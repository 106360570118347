 $("#gallery").click(function() { 
      
          $("#subMenu").toggle(1000);
      });

 $("#galleryMobile").click(function() { 
      
          $("#subMenuMobile").toggle(500);
      });

